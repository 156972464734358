<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>
              <v-icon class="mr-3" color="primary"> mdi-group</v-icon> Tags
            </h3>
            <v-spacer></v-spacer>
            <v-btn class="ml-3" color="primary" @click="newGroup">
              Adicionar Tag
            </v-btn>
          </v-card-title>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-divider></v-divider>
          <v-container>
            <v-sheet class="mx-auto mt-6 pa-0" elevation="6">
              <div v-if="loadingTag">
                <v-col
                  cols="12"
                >
                  <div class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </div>
              <draggable v-if="!loadingTag" v-model="tag_list.data" :disabled="search.length" :options="dragOptions" @change="onTagListChange">
                <v-chip v-for="(item, index) in filteredTagList" :key="index" color="default" class="item ma-2" draggable>
                  <div class="chip-content">
                    <div class="chip-buttons">
                      <v-icon
                          small
                          title="Editar"
                          class="mr-2"
                          @click="editTag(item.id)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon small title="Excluir" class="mr-2" @click="deleteTagItem(item)">
                        mdi-delete
                      </v-icon>
                    </div>
                    <div class="chip-text">
                      <v-icon>{{ item.icon }}</v-icon> {{ item.name }}
                    </div>
                  </div>
                </v-chip>
              </draggable>
            </v-sheet>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      selectedTag: {},
      dialog: false,
      loadingTag: true,
      list: [],
      search: "",
      dragOptions: {
        animation: 200,
      },
    };
  },
  async mounted() {
    await this.loadDataTag();
  },
  computed: {
    ...mapState("tag", ["tag_list", "tag_delete"]),
    filteredTagList() {
      if (this.tag_list.data.length) {
        return this.tag_list.data.filter((item) =>
            item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.tag_list.data;
    },
  },
  methods: {
    ...mapActions("tag", ["loadTag", "deleteTag", "changeTagPosition"]),

    async loadDataTag() {
      this.loadingTag = true;
      await this.loadTag().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.list = [...this.tag_list.data];
      this.loadingTag = false;
    },

    async onTagListChange() {
      this.loadingTag = true;
      await this.changeTagPosition(this.filteredTagList).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      await this.loadDataTag();
      this.loadingTag = false;
    },

    newGroup() {
      this.$router.push({ name: "tag-new" });
    },

    editTag(id_group) {
      this.$router.push({ name: "tag-edit", params: { id: id_group } });
    },

    async deleteTagItem(tag) {
      this.loadingTag = true;
      await this.$swal({
        icon: "warning",
        text: `Tem certeza que deseja excluir a Tag ${tag.name}?`,
        showCancelButton: true,
        confirmButtonColor: "#445E93",
        confirmButtonText: "Sim, remover o tag!"
      }).then(async result => {
        if (result.value) {
          await this.deleteTag(tag).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
          if (!this.tag_delete.success) {
            await this.$swal("Oops...", this.tag_delete.message, "error");
          } else {
            await this.$swal({icon: "success", title: this.tag_delete.message});
          }
        }
      });
      this.loadingTag = false;
    }
  }
};
</script>

<style scoped>
.item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 5px;
  box-sizing: border-box;
  width: calc(25% - 10px);
  display: inline-block;
  max-width: 200px;
  height: 150px;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  position: relative;
}

.chip-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chip-buttons {
  position: absolute;
  top: 5%;
  right: 5%;
  flex-direction: column;
}

.chip-text {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>